﻿import { UrlManager } from "../Utilities/UrlManager";
import { Utilities } from "../Utilities/Utilities";
import { SearchList } from "../Pages/SearchList";
import { MessageManager } from "../Utilities/MessageManager";
import { InViewPort } from "../Utilities/InViewPort";

export class Catalogue {
    readonly templateDetailsCls:string = "template-details";
    readonly imageThumbnailCls: string = "image-thumbnail";
    readonly containerCls: string = "catalogue-container";
    readonly filterContainerCls:string = "catalogue-filters";
    readonly headerContainerCls: string = "catalogue-header";
    readonly footerContainerCls: string = "catalogue-footer";
    readonly breadcrumbContainerCls:string = "breadcrumb-container";
    readonly action:string = "GetCatalogue";
    readonly loadingCls:string = "loading";
    readonly resultCls:string = "catalogue";
    readonly templateIDPrefix: string = "template";
    readonly mobileBreakPoint: number = 768;

    
    urlManager: IUrlManager;
    utilities: Utilities;
    apiClient: IApiClient;
    searchController: SearchList;
    messageManager: IMessageManager;
    filterPrefixConfig: IFilterPrefixConfig;

    controllerUri: string;
    isQueryRunning: boolean = false;

    containerElement:HTMLElement = null;
	resultElement: HTMLElement = null;
    regionID: string = null;
    pageID: number = null;
    categoryID: number = null;
    categoryUri: string = null;
    elementNameDataMap: [string, any][] = [];
    templateQueriesEnabled: boolean = true;

    constructor(apiClient: IApiClient, utilities: Utilities, urlManager: IUrlManager, searchController: SearchList,
         messageManager: IMessageManager, pageID: number, controllerUri: string, filterPrefixConfig: IFilterPrefixConfig, templateQueriesEnabled:boolean) {
        let me:this = this,
            element:JQuery<HTMLElement> = jQuery(`.${me.containerCls}`);

        me.apiClient = apiClient;
        me.utilities = utilities;
        me.urlManager = urlManager;
        me.searchController = searchController;
		me.messageManager = messageManager;

        me.controllerUri = controllerUri;
        me.filterPrefixConfig = filterPrefixConfig;
        me.templateQueriesEnabled = templateQueriesEnabled;

        if (element.length === 1) {
            me.containerElement = element[0];
            me.pageID = pageID;

            me.initScrollEvent();
            me.initListeners();
            me.initContent();

            jQuery(document).ready(() => {
                // This nukes the link inside the h4 - removing for a hotfix, to do it better the dom needs to be reconfigured a bit
                // to work better with the plugin
                // (jQuery('.template-details h4') as any).ellipsis();
                me.needToLoadNext();
                setTimeout((): void => { me.jumpToPage() }, 200);
            });
        }
    }

    initContent(): void {
        let me: this = this;

        me.initItemClick();
        me.initPreviousNextListeners();
    }

    initItemClick():void {
        let me:this = this;

        jQuery(this.containerElement).find(`.${this.templateDetailsCls}`).off("click").click((event: JQuery.Event): void => {
            if (window.innerWidth < me.mobileBreakPoint) {
                me.utilities.expandMobile(event, jQuery(event.currentTarget));
            } else {
                me.openTemplateLink(jQuery(event.currentTarget));
            }
        });

        jQuery(this.containerElement).find(`.${this.imageThumbnailCls}`).off("click").click((event: JQuery.Event): void => {
            if (window.innerWidth >= me.mobileBreakPoint) {
                me.openTemplateLink(jQuery(event.currentTarget).next(`.${this.templateDetailsCls}`));
            }
        });

    }

    initListeners(isLoadingFullPage: boolean = true):void {
        let me:this = this;

        if (me.templateQueriesEnabled == true) {
            // first update all elements previously retrieved when scrolling. Will be empty if it is a page reload
            me.elementNameDataMap.forEach(function (map) {
                me.updateDomElementByName(map[0], map[1]);
            });
            // then process new page
            me.processTemplatePageLoading(isLoadingFullPage, me.pageID);
        }

        jQuery(".catalogue-filters a").off("click").click((e):void => {
            e.preventDefault();
            me.pageID = 1;
            jQuery(e.currentTarget).parentsUntil(".radio-filter").parent().find("input[type=radio]").prop("checked", "checked");
            me.getCatalogue();
        });

        jQuery(".catalogue-filters input[type=radio]").off("change").change((): void => {
            me.pageID = 1;
            me.getCatalogue();
        });
    }

    initPreviousNextListeners(): void {
        let me: this = this;

        jQuery(".btn.showmore").off("click").click(function (event: JQuery.Event): void {
            event.preventDefault();

            me.pageID++;
            me.getCatalogue(false);
        });
    }

    initScrollEvent(): void {
        let me: this = this,
            loadMore: Function = me.utilities.debounce((): void => {
                me.needToLoadNext();
            }, 100, false);

        window.onscroll = (ev: UIEvent): any => {
            loadMore();
        };
    }

    openTemplateLink(container: JQuery<HTMLElement>): void {
        let templateLinkCls: string = "template-link",
            templateLink: JQuery<HTMLElement> = container.find(`.${templateLinkCls}`);

        if (templateLink.length > 0) {
            document.location.href = templateLink.attr('href');
        }
    }

    jumpToPage(): void {
        let me: this = this,
            pageElement: JQuery<HTMLElement> = jQuery(me.containerElement).find(`.catalogue-item.page-${me.pageID}:first`);

        if (me.pageID > 1 && pageElement.length > 0) {
            me.utilities.scrollToElement(pageElement);
        }
    }

    needToLoadNext(): void {
        let me: this = this,
            showMoreBtn: JQuery<HTMLElement> = jQuery(me.containerElement).find(".btn.showmore");
        if (!me.isQueryRunning && showMoreBtn.length === 1) {
            if (InViewPort.isAnyPartOfElementInViewport(showMoreBtn[0])) {
                me.pageID++;
                me.getCatalogue(false);
            }
        }
    }

    getCatalogue(showFullScreenLoader:boolean = true): void {
        let me: this = this;

        if (me.isQueryRunning) {
            return;
        }
        me.extractCategoryIdFromBreadcrumb();
        let loaderContainerElement: JQuery<HTMLElement> = showFullScreenLoader ? jQuery(me.containerElement) : jQuery(me.containerElement).find(".showmore-container"),
            selectedCategoryElement: JQuery<HTMLElement> = jQuery(".catalogue-filters input[type=radio]:checked"),
            categoryID: number = selectedCategoryElement.length == 0 ? me.categoryID : selectedCategoryElement.val() as number,
            categoryUri: string = selectedCategoryElement.length == 0 ? me.categoryUri : selectedCategoryElement.next("label").find("a").attr("data-uri"),
            ajaxUrl: string = me.constructAjaxUrl(categoryID, me.pageID),
            url: string = me.constructUrl(categoryID, categoryUri, me.pageID),
            apiCallback:IApiCallback = {
                success: (data:ICataloguePartial):void => {
                    jQuery(me.containerElement).find(`.${me.resultCls}`).html(data.html.catalogueHtml);
                    jQuery(me.containerElement).find(`.${me.filterContainerCls}`).html(data.html.filterHtml);
                    jQuery(`.${me.headerContainerCls}`).html(data.html.headerHtml);
                    jQuery(me.containerElement).find(`.${me.footerContainerCls}`).html(data.html.footerHtml);

                    jQuery(`.${me.breadcrumbContainerCls}`).find("ul:not(.root):last").remove();
                    jQuery(`.${me.breadcrumbContainerCls}`).append(data.html.breadcrumbHtml);

                    me.initListeners(false);
                    me.utilities.initHeaderBackgrouncCls();

                    me.initContent();

                    me.categoryID = categoryID;
                    me.categoryUri = categoryUri;

                    me.urlManager.pushState(null, document.title, url);
                    // This nukes the link inside the h4 - removing for a hotfix, to do it better the dom needs to be reconfigured a bit
                    // to work better with the plugin
                    // (jQuery('.template-details h4') as any).ellipsis();
                },
                complete: (textStatus: string) => {
                    jQuery(loaderContainerElement).removeClass(me.loadingCls);
                },
                error:(textStatus: string, errorThrown: string) => {
                    me.messageManager.showApiErrorMessage(errorThrown);
                },
                scope: me
            };

        jQuery(loaderContainerElement).addClass(me.loadingCls);
        me.apiClient.getData(ajaxUrl, apiCallback);
    }

    constructAjaxUrl(categoryID:number, pageID: number):string {
        let me: this = this,
            url: string = `${me.controllerUri}/${me.action}/`,
            parts: string[] = [];

        if (categoryID > 0) {
            parts.push("categoryID=" + categoryID);
        }

        if (pageID > 1) {
            parts.push("pageID=" + pageID);
        }

        if (parts.length > 0) {
            url += "?" + parts.join("&");
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    constructUrl(categoryID: number, categoryUri: string, pageID: number): string {
        let me: this = this,
            url: string = `${me.controllerUri}`;

        // the order of this is important because the routes are set up in the same order
        if (categoryID > 0) {
            url += `/${me.filterPrefixConfig.category}-${categoryID}-${me.urlManager.encodeUriComponent(categoryUri)}`;
        }

        if (pageID > 1) {
            url += `/${me.filterPrefixConfig.paging}-${pageID}`;
        }

        return me.urlManager.getAbsoluteUrl(url);
    }

    processTemplatePageLoading(isLoadingFullPage:boolean, nextPageID:number):void {
        let me:this = this,
            regexp = /template-(\d+)-(\d+)/,
            nameIdTuples: [number, string[]][] = [];

        let catalogueItems = jQuery(me.containerElement).find(`.${me.resultCls}`).find(".catalogue-item");

        if (!isLoadingFullPage) {
            // we match the page on DOM, to not call the api for templates that we already fetched before (user is scrolling)
            catalogueItems = catalogueItems.filter(`.page-${nextPageID}`);
        }

        // select unique template name with templateid and categoryid
        catalogueItems.each((index: number, element: HTMLElement):void => {
            let match = jQuery(element).attr("id").match(regexp),
                categoryID: number = parseInt(match[1], 10),
                templateID: number = parseInt(match[2], 10);

            if (isNaN(templateID) || isNaN(categoryID)) {
                return;
            }

            let elementName: string = `#${me.templateIDPrefix}-${categoryID}-${templateID}`
            // if the elementName is already loaded from a previous page, we don't have to load it again
            if (me.elementNameDataMap.some(tuple => tuple[0] == elementName)) {
                return;
            }

            let matchingTuple = nameIdTuples.filter(tuple => tuple[0] == templateID);
            if (matchingTuple.length == 0) {
                nameIdTuples.push([templateID, [elementName]]);
            }
            else {
                let templateIndex = nameIdTuples.indexOf(matchingTuple[0])
                if (nameIdTuples[templateIndex][1].indexOf(elementName) == -1) {
                    nameIdTuples[templateIndex][1].push(elementName);
                }
            }
        });

        nameIdTuples.forEach(function (tuple) {
            me.getTemplateDataAndUpdateGlobal(tuple[0], tuple[1]);
        })
    }

    getTemplateDataAndUpdateGlobal(templateID: number, elementNames: string[]):void {
        let me:this = this,
            apiCallback: IApiCallback = {
                success: (data: any): void => {
                    // on success, process all elements
                    elementNames.forEach(function (elementName) {
                        me.updateDomElementByName(elementName, data);
                        // if the element was in the api call, it for sure was not in elementNameDataMap yet
                        me.elementNameDataMap.push([elementName, data]);
                    });
                },
                complete: (textStatus: string) => {
                    // finally, set everything as not loading
                    elementNames.forEach(function (elementName) {
                        let templateElement: JQuery<HTMLElement> = jQuery(me.containerElement).find(elementName);
                        templateElement.removeClass(me.loadingCls);
                    });
                },
                error:(textStatus: string, errorThrown: string) => {
                    me.messageManager.showApiErrorMessage(textStatus);
                },
                scope: me
            };

        // first set all elements as loading
        elementNames.forEach( function(elementName) {
            let templateElement: JQuery<HTMLElement> = jQuery(me.containerElement).find(elementName);
            templateElement.addClass(me.loadingCls);
        });
        // make one api call for the template
        me.searchController.GetEventsAndOADataForTemplate(templateID, apiCallback);
    }

    updateDomElementByName(elementName: string, data: any) {
        let me: this = this;

        let templateElement: JQuery<HTMLElement> = jQuery(me.containerElement).find(elementName);

        let templateInformationElement: JQuery<HTMLElement> = templateElement.find(".template-event-information"),
            templateHasEventOrOAElement: JQuery<HTMLElement> = templateInformationElement.find(".template-has-events-or-oa");

        if (data.scheduledEventsNum > 0) {
            templateHasEventOrOAElement.find("a > span").html(`(${data.scheduledEventsNum})`);
        } else if (data.onlineActivityInfo != null) {
            templateHasEventOrOAElement.find("a").html(me.utilities.escapeHtml(data.onlineActivityInfo.referenceTerms.singular));
        } else {
            templateHasEventOrOAElement.hide();
            templateInformationElement.find(".register-interest").show();
        }
    }

    extractCategoryIdFromBreadcrumb() {
        // If a category is selected, the last non-root element in the breadcrumb will have the page URL including the
        // category in the format "cat-{CatID}-{CatName}"
        // e.g: https://dev.arlo.local/w/events/cat-13-computer/
        let selectedCategoryElement: JQuery<HTMLElement> = jQuery('.breadcrumb-container li').not('.root').last();
        if (selectedCategoryElement) {
            // If the breadcrumb element exists, get the link from the child anchor tag
            let link = selectedCategoryElement.find('a').prop('href');
            if (!link) {
                return;
            }
            // Remove trailing slash - if any - for lastIndexOf to work properly
            if (link.endsWith("/")) {
                link = link.substring(0, link.length - 1);
            }
            // The category string should now be "cat-13-computer""
            let categoryString = link.substring(link.lastIndexOf('/') + 1);
            let firstIndexOfDash = categoryString.indexOf('-');
            // Category ID is what is between the first and the second dashes
            this.categoryID = categoryString.substring(firstIndexOfDash + 1, categoryString.indexOf('-', firstIndexOfDash + 1));
            // Category URI is everything after the second dash
            this.categoryUri = categoryString.substring(categoryString.indexOf('-', firstIndexOfDash + 1) + 1);
        }
    }
}

